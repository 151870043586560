import React from 'react'
import Footer from '../component/Footer'
import ShoppingDetail from "../component/ShoppingDetail";

 const Shopping = () =>{
    return (
        <div className='container'>
            <ShoppingDetail />
            <Footer/>
        </div>
    )
}

export default Shopping;
